import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import * as jQuery from 'jquery';
import ModalMessage from './components/ModalMessage.vue'
import TrueMessage from './components/TrueMessage.vue'
import swal from 'sweetalert2';
window.Swal = swal;
 window['jQuery'] = jQuery;
 
 
createApp(App).use(store).use(router).component('ModalMessage',ModalMessage).component('TrueMessage',TrueMessage).mount('#app')
