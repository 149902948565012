
<template>
<body class="hold-transition login-page fondo-sokso"  >
  <div class="login-box">
    <div class="card" style="    border-radius: 15px;">
      <div class="card-body login-card-body" style="    border-radius: 15px;" >
          <p class="login-box-msg">Está a solo un paso de su nueva contraseña, recupere su contraseña ahora.</p>
        <div class="logo-sokso">
        <img src="../assets/img/logo.png" class="product-image" style="padding-bottom: 10px;"> </div>
        <form action class="" >
          <div class="input-group mb-3">
          <input type="password" class="form-control" placeholder="Password" autocomplete="off" v-model="NuevaPass">
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-lock"></span>
            </div>
          </div>
        </div>
        
          <div class="input-group mb-3">
          <input type="password" class="form-control" placeholder="Confirmar Password" autocomplete="off" v-model="ReNuevaPass">
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-lock"></span>
            </div>
          </div>
        </div>
          <div class="row">
          
            <div class="col-12">
            <input type="button"  class="btn btn-primary btn-block" value="Cambiar Contraseña" @click="CambiarPassword()">
            </div>
            <div class="col-12"> 
            <p v-if="error" class="error" style="margin-top: 20px; text-align: center;color: red;">Su correo no es válido.</p>
            </div>
            <div class="overlay-wrapper"  v-if="load">
            <div class="overlay" style="flex-direction: column;">
            <i class="fas fa-3x fa-sync-alt fa-spin"></i>
            <div class="text-bold pt-2">Cargando...</div></div>
          </div>
          </div>
        </form>
         <p class="mt-3 mb-1">
        <router-link to="/" class="color-sokso" >Login</router-link>
      </p>  
      </div>
    </div>
  </div>
</body>
</template>
<script>
import axios from "axios";
export default {
   data: () => ({
    
    error: false,
    load : false,
     NuevaPass :"",
      ReNuevaPass :"",
  }),  
  mounted() {
      let ipauth = this.$store.state.ipauth;
        var idarray = location.search.split('?id=');
        let token = idarray[1]
        if(location.search == ""){
            window.location.href = ipauth;
          }
   
         /* 
        var filtroid = idarray.filter(Number); 
        var id = filtroid*1;
         if(id == 0){
            window.location.href = ipauth;
          } */
  },
  methods : {

    CambiarPassword(){
        let ipauth = this.$store.state.ipauth;
        var idarray = location.search.split('?id=');
        let token = idarray[1]
        
        var password = ""; 
        var reppassword = "";
        var dataprocess = "";
        var password = this.NuevaPass;
        var reppassword = this.ReNuevaPass;
        if(password == "" && reppassword == ""){
           const Toast =  Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000
            }).fire({
              icon: 'error',
              title: 'Debe llenar todos los campos'
            });
          return
        }
         if (password.length < 4 || reppassword < 4) {
        Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        }).fire({
          icon: "error",
          title: "La contraseña debe tener mínimo 4 dígitos",
        });
        return;
      }
    if(password == reppassword){
    let ipbackendoms = this.$store.state.backendoms;
  
     var bodyFormData = new FormData();
      bodyFormData.append("sPassNuevo", password);
      bodyFormData.append("sPassConfirma", reppassword);
      bodyFormData.append("sTokenRecuperar", token);
      axios.post(ipbackendoms+"/api/Administracion/CambioPasswordUsuarioId", bodyFormData)
      .then((response) => {
  
      const data_res = response.data.bSatisfactorio;
          console.log(data_res)
          if (data_res == true) { 
         Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000
            }).fire({
              icon: 'success',
              title: 'Se cambio su contraseña con éxito'
            });

            setTimeout(() => {
            window.location.href = ipauth;
            return;
            }, 2500);
           
            }
        else {
        
            return
        }
         
      });
       

    }
    else {
        this.NuevaPass ="";
        this.ReNuevaPass ="";
         Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000
            }).fire({
              icon: 'error',
              title: 'Las contraseña no son iguales'
            });
      return
    }

   
    }
  }
}

</script>