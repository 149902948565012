<template>
    <transition name="bounce">
        <div 
            v-if="md.show"
            class="vc-overlay bg-none"
            id="vueMd"
        >
        <transition name="zoom">
            <div class="vc-container">
              <div class="vc-header" v-if="md.titulo" >
                <div class="vc-title" style="background-color: rgb(90, 45, 130);">
                  <div class="vc-title-content"  v-html="md.titulo"></div>
                  <!--
                   <div class="vc-close" >
                    <button type="button" class="close" @click="md.show = false">
                      <span aria-hidden="true" style="color:#fff;">×</span>
                    </button>
                  </div>
                  -->
                </div>
                
              <div class="vc-body modal-error" style="background-color: rgb(90, 45, 130);" >
                    <div class="contenido-dowland">
                        <img src="http://crm.sokso.com/mantenimiento/logo.png" alt="SOKSO SMART" width="440" height="160" class="h-auto w-50">
                        <h1 class="text-center text-uppercase" style="color:white"><strong style="font-size: 18px;">...Regresamos Pronto...</strong></h1>
                        <p class="w-100"><span class="d-block h-100 w-100"></span></p>
                        <br />
                  
                        
                    </div>
                </div>
              </div>
            </div>
        </transition>
        </div>
    </transition>       
</template>

<script >
export default {
props: ['md'],
name: 'ModalError',
data() {
    return {
      isShow: true,
      
    }
  },
  methods: {
    handleClickOverlay({ target }) {
      if (target.id == 'vueMd') {
        this.md.show = false;
      }
    },
   
  }
}
</script>
<style scoped>


.vc-overlay.bg-none{
    background-color: rgba(0,0,0,0.5);
    height: 100vh;
    top: 0;
}
.vc-container {
  background: rgb(90, 45, 130) !important;
  position: relative !important;
}
.vc-title{
    position: relative;
}
.vc-close{
    
    margin: 0 10px;
    position: absolute;
    right: 0;
    height: 100%;

}
.vc-title-content{
    width: 100%;
    color: #fff;
}
.vc-body {
    color: #000 !important;
    text-align: justify;

}
.vc-header{
    margin:0 !important;
    padding: 0 !important;
    color: #fff;
}
.modal-error{
    text-align: center;
    margin: 15px;
}
.w-100{
    border: 1px solid #fff;
    height: 25px;
    max-width: 400px;
    margin: auto;
}
p span {
    background: #fff;
    transform: scaleX(0);
    transform-origin: left center;
    animation: barra 2s linear infinite;
}

@keyframes barra { 
 0% { opacity:1; transform:scaleX(0) }
 50% { opacity:1; transform:scaleX(0.5) }
 100% { opacity:0.1; transform:scaleX(1) }
 }





a {
  outline: none;
  text-decoration: none;
  padding: 2px 1px 0;
  color: white !important;
}

a:link {
  color: #265301 !important;
}

a:visited {
  color: #437A16;
}

a:focus {
  border-bottom: 1px solid;
  background: #333333;
}

a:hover {
  border-bottom: 1px solid;
  background: #333333;
}

a:active {
  background: #333333;
  color: #CDFEAA;
}

</style>
