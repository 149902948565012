<template>
    <transition name="fade">
        <div 
           v-if="isShow"
            @click="handleClickOverlay"
            class="vc-overlay"
            id="vueModalMessage"
        >
        <transition name="zoom">
            <div class="vc-container">
              <div class="vc-header" v-if="msj.titulo" >
                <div class="vc-title" style="background-color: rgb(90, 45, 130);">
                  <div class="vc-title-content"  v-html="msj.titulo"></div>
                   <div class="vc-close">
                      <button type="button" class="close" @click="this.msj.isModalMessage = false;" >
                        <span aria-hidden="true" style="color:#fff">×</span>
                      </button>
                    </div>
                </div>
                
              <div class="vc-body" v-html="msj.mensaje">
                
              </div>
              
              </div>
              <!--<div class="vc-footer">
                <div class="row">
                  <div class="col-md-12 text-right">
                    <slot></slot>
                  </div>
                </div>
              </div>-->
            </div>
        </transition>
        </div>
    </transition>       
</template>

<script>
export default {
props: ['msj'],
name: 'vueModalMessage',
data() {
    return {
      isShow: true,
    }
  },
  methods: {
    handleClickOverlay({ target }) {
    /*  if (target.id == 'vueModalMessage') {
         this.msj.isModalMessage = false;
      }*/
    },
  }
}
</script>
<style scoped>
.vc-container {
  background: #fff !important;
}
.vc-body {
    color: #000 !important;
    text-align: justify;

}
.vc-header{
    margin:0 !important;
}
</style>