import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import RecuperarPassword from '../views/RecuperarPassword.vue'
import NuevaPassword from '../views/NuevaPassword.vue'



const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
 
  {
    path: '/recuperar-password',
    name: 'recuperar-password',
    component: RecuperarPassword
  },
  {
    path: '/nueva-password',
    name: 'nueva-password',
    component: NuevaPassword
  },
  
 
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
          let url ="";
          var urlactual = window.location.pathname;
          console.log(urlactual)

          routes.forEach((el) => {
  
          if( el.path == urlactual){
          url=1;
          return
          }

          })
   
          if(url != 1){
          window.location.href = "/";
          }
export default router
