import axios from "axios";
import Cookies from "js-cookie";
import store from '../store'


const ENDPOINT_PATH = store.state.route_backend+"/api/Seguridad";


export default {
  setUserLogged(userLogged) {
    Cookies.set("userLogged", userLogged);
  },
  getUserLogged() {
    return Cookies.get("userLogged");
  },


  
  login(email, password) {
    const user = { email, password };
    var bodyFormData = new FormData();
    bodyFormData.append('sUserName', email);
    bodyFormData.append('sPassword', password);
    return axios.post(ENDPOINT_PATH, bodyFormData);
  },

  state: {
    session: {
      logged: false,
      token: '',
      userInfo:{}
    }
    },
    getters: {
      session: state => state.session,
      logged: state => state.session.logged,
    token: state => state.session.token,
    },




};
