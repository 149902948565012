<!-- Template Login -->
<template>
  <body class="hold-transition login-page body-login" id="scroll"  >
    <div class="dialog-content" v-if="anuncios">
       <div class="content-publish">
          <a :href="anuncios.sEnlaceURL" target="_blank"><img :src="anuncios.sArchivoAnuncio" class="img-publish"></a>
         <div class="load-content">
           <div class="load-close" v-if="anuncioclose">
             <a href="javascript:void(0);" class="btn-close" @click="anuncios=null">Cerrar X</a>
           </div>
           <div id="countdown" v-if="!anuncioclose">
               <svg class="svg-count">
                 <circle id="circle-countdown" r="13" cy="50%" cx="50%"></circle>
               </svg>
           </div>
         </div>
       </div>
    </div>
 
    <div v-if="CodigoS3 == 'PORTADA_LOGIN'">
    <img :src="FotoS3" style="margin-top: 5px;    margin-bottom: 5px;width:361px;height:583px">
    </div>
   <div class="login-box">
     <div class="card" style="border-radius: 15px;">
       <div class="card-body login-card-body" style="    border-radius: 15px;" >
         <div class="logo-sokso">
         <img src="../assets/img/logo.png" class="product-image" style="padding-bottom: 10px;"> </div>
         <form action class=""  @submit.prevent="login">
           <div class="input-group mb-3">
           <input type="text" class="form-control" placeholder="Usuario" autocomplete="off" v-model="email" required id="email">
             <div class="input-group-append">
               <div class="input-group-text">
               <span class="fas fa-user"></span>
               </div>
             </div>
           </div>
           <div class="input-group mb-3">
           <input type="password" class="form-control" autocomplete="off" placeholder="Contraseña" v-model="password" id="password" required>
             <div class="input-group-append">
               <div class="input-group-text">
               <span class="fas fa-lock"></span>
               </div>
             </div>
           </div>
           
           <div class="row">
          
                <div class="col-12 asterik" >
             <b class="fas fa-asterisk" style="margin-right: 5px;"></b><span style="    margin-bottom: 1rem;">Si es la primera vez que ingresas, usa tu DNI como usuario y contraseña.</span></div>
             <div class="col-12">
             <input type="submit"  id ="hola" class="btn btn-primary btn-block" value="Ingresar">
             </div>
             
             <div class="col-12"> 
             <p v-if="error" class="error" style="margin-top: 20px; text-align: center;color: red;">El DNI o la contraseña son incorrectos.</p>
             </div>
             <div class="overlay-wrapper"  v-if="load">
             <div class="overlay" style="flex-direction: column;">
             <i class="fas fa-3x fa-sync-alt fa-spin"></i>
             <div class="text-bold pt-2">Cargando...</div></div>
           </div>
           </div>
         </form>
          <p class="mt-3 mb-1" style="text-align:center">
         <router-link to="/recuperar-password" class="color-sokso">Olvidé mi contraseña</router-link>
       </p> 
       <div class="col-12">
       <p class="text-msg">Para ingresar al sistema es necesario que te encuentres registrada como Estrella Sokso.</p>
       </div>  
       <div class="col-12" style="margin-top: 10px; text-align: center;">
      
        <a   href="https://cutt.ly/SwK0a5R7"  class="btn btn-primary" target="_blank" style="margin-bottom: 10px;background-color: #007bff;
     border-color: #007bff;">
        <i class="fas fa-download" style="margin-right: 10px;"></i>
       <b>Manual de Estrella</b>
       </a>
       <a   href="/pdf/TERMINOS-Y-CONDICIONES-SMART.pdf" download >
    
       <p style="color: color: #007bff;text-decoration: underline;">Términos y Condiciones </p>
       </a>
 
       </div> 
 
 
                                          
       </div>
     </div>
   </div>

 
  <ModalError v-show="modalError.show" :md="modalError" >

      </ModalError>
    </body>

</template>
<!-- Login - Css -->
<style>
/* CSS Login - Rutas*/
@import './../assets/plugins/fontawesome-free/css/all.min.css';
@import './../assets/css/adminlte.min.css';
@import './../assets/css/sokso_login.css';
@import './../assets/css/adminlte.css';
@import './../assets/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
/* CSS Login - Código*/
.btn-primary {
  color: #fff;
  background-color:var(--sokso);
  border-color: var(--sokso);
  box-shadow: none;
}
.btn-primary:hover {
  background:var(--sokso);
  color: #fff;
  border: 1px solid var(--sokso);
  opacity: 0.8;
}
.asterik {
     margin-bottom: 1rem;
    text-align: center;
    color: #000;
}
.text-msg {
      margin-bottom: 0;
    text-align: center;
}
</style>
<!-- Login - Script -->
<script>

import auth from '@/logic/auth'
import axios from 'axios'
import ModalError from '../components/ModalError.vue'
export default {
  components: { ModalError },
  data: () => ({
    email: '',
    password: '',
    error: false,
    load: false,
    anuncios: null,
    anuncioclose: false,
    prueba: '',
    FotoS3: '',
    CodigoS3: '',
    modalError: {
      show: false,
      titulo: 'PÁGINA EN MANTENIMIENTO'
    }
  }),
  created () {
    this.Scrolldown()
    this.contenidoLoad()
  },
  mounted () {
    const ipbackendsac = this.$store.state.backendoms
    axios.get(ipbackendsac + '/api/Sistema/ListarFotosEstaticas?sFiltro=PORTADA_LOGIN')
      .then((res) => {
        this.CodigoS3 = res.data.oListaFotos[0].sCodigo
        this.FotoS3 = res.data.oListaFotos[0].sUrlFoto

        // console.log(res.data);
      }).catch((error) => {
        // this.modalError.show = true;
      })

    var dni = 944207834

    const url = `https://crm.sokso.com/api/Hubspot/ValidacionTokenSms/${dni}`
    console.log(url)

    fetch(url)
      .then(response => {
        // Verifica si la solicitud fue exitosa (código de estado 200)
        if (!response.ok) {
          throw new Error('La solicitud no fue exitosa')
        }
        // Parsea la respuesta JSON si es aplicable
        return response.json()
      })
      .then(data => {
        // Manipula los datos obtenidos aquí
        console.log(data)
        const result = data.sMessage.split('|')
        const estado = data.bSatisfactorio
        console.log(result)
        console.log(estado)
      })

    /* const url = 'https://crm.sokso.com/api/Hubspot/ValidacionTokenSms';
      const url = 'http://54.205.209.86:8368/API/Hubspot/ValidacionTokenSms';
const data = {
  sData: celular,

};

fetch(url, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(data)
})
  .then(response => response.json()) // Convierte la respuesta a JSON
  .then(data => {
    // Aquí puedes trabajar con los datos de respuesta
    console.log(data);
  })
  .catch(error => {
    // Manejo de errores
    console.error('Error:', error);
  });

*/
  },

  methods: {
    anuncioload (tiempo) {
      var time = tiempo
      var initialOffset = '82'
      var i = 1
      var circle_down = document.getElementById('circle-countdown')
      circle_down.style.strokeDashoffset = initialOffset - (1 * (initialOffset / time))

      var interval = setInterval(() => {
        if (i == time) {
          this.anuncioclose = true
          clearInterval(interval)
          return
        }
        circle_down.style.strokeDashoffset = initialOffset - ((i + 1) * (initialOffset / time))
        i++
      }, 1000)
    },
    contenidoLoad () {
      const ipbackendsac = this.$store.state.backendoms
      axios.get(ipbackendsac + '/api/Anuncio/AnunciosPorSeccion?nSeccion=1')
        .then((res) => {
          this.anuncios = res.data.oListaAnuncios[0]
          if (res.data.oListaAnuncios.length > 0) {
            this.$nextTick(() => { this.anuncioload(this.anuncios.nSegundosVisualizacion) })
          }
        }).catch((error) => {
        // console.log(res.data);
        }).catch((error) => {
        // this.modalError.show = true;
        })
    },
    Scrolldown () {
      setTimeout(() => {
        window.scroll(0, 3000)
      }, 300)
    },
    async login () {
      try {
        const response = await auth.login(this.email, this.password)
        const token = response.data.sToken
        const role = response.data.o.nIdRoles
        const result = response.data.oResult.bSatisfactorio
        const User = response.data.o.cUser
        console.log(role)

        if (result == true) {
          this.load = true

          //  if(result == false){

          //   const Toast = Swal.mixin({
          //                 toast: true,
          //                 position: "top-end",
          //                 showConfirmButton: false,
          //                 timer: 3000,
          //                 });
          //                 Toast.fire({
          //                 icon: "error",
          //                 title: response.data.oResult.sMessage,
          //                 });
          //           }

          if (role == 1 || role == 23 || role == 18 || role == 32) {
            window.location.href = this.$store.state.route_promotora + '/auth?token=' + token + '&role=' + role
            return
          }
          if (role == 2 || role == 15 || role == 20) {
            window.location.href = this.$store.state.route_personal + '/auth?token=' + token + '&role=' + role
            return
          }
          if (role >= 3 && role <= 14) {
            // window.location.href = this.$store.state.route_sac+'/auth?token=' +token+'&role='+role;
            window.location.href = this.$store.state.route_sac + '/auth?token=' + token + '&role=' + role
            return
          }
          if (role == 16 || role == 17 || role == 19 || (role >= 21 || role <= 25)) {
            // window.location.href = this.$store.state.route_sac+'/auth?token=' +token+'&role='+role;
            window.location.href = this.$store.state.route_sac + '/auth?token=' + token + '&role=' + role
            return
          }

        /* if (role == 18 ||  role == 20) {
        window.location.href = this.$store.state.route_tienda+'/auth?token=' +token+'&role='+role;
          // window.location.href = this.$store.state.route_tienda+'/auth?token=' +token;
          return;
        }
        */
        } else {
          this.email = ''
          this.password = ''
          Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000
          }).fire({
            icon: 'error',
            title: response.data.oResult.sMessage
          })
        }
      } catch (error) {
        this.error = true
      }
    }
  }
}

</script>
