<!-- App - Login -->

<template>

<router-view  ></router-view>
 
</template> 
<!-- App - Script -->
<script>
import Login from './views/Login'

export default {
  components: {
    Login,
  },

    mounted(){


    },
}
</script>
<style>

</style>

