import { createStore } from 'vuex'

export default createStore({
  state: {
    route_backend : 'https://api.sokso.com',
    route_personal : 'https://empresaria.sokso.com',
    route_promotora : 'https://promotora.sokso.com',
    route_tienda : 'https://tienda.sokso.com',
    route_sac : 'https://oms.sokso.com',
    backendoms : 'https://apioms.sokso.com',
    ipauth : 'https://smart.sokso.com',
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
