<!-- Template Login -->

<!-- https://localhost:44342/api/Acceso/ValidarUsuario?sUsuario=43478948
http://localhost:44342/api/Acceso/ValidarUsuario?sUsuario=43478948 -->


<template>
  <body class="hold-transition login-page fondo-sokso" >
    <div class="login-box">
      <div class="card" style="border-radius: 15px;">
        <div class="card-body login-card-body" style="border-radius: 15px;" >
        <!--<p class="login-box-msg">
        ¿Olvidaste tu contraseña? Aquí puede obtener fácilmente una nueva contraseña.</p> -->
          <div class="logo-sokso">
            <img src="../assets/img/logo.png" class="product-image" style="padding-bottom: 10px;"> 
          </div>

          <form action class="" @submit.prevent>
            <div class="input-group mb-3" v-if="recuperar">
              <input type="text" class="form-control" placeholder="Ingresa tu usuario" v-model="correo" required id="email" >
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-user"></span>
                </div>
              </div>
            </div>
              
            <p style="color:#5A2D82 !important;">¿Cómo te gustaría recuperar tu contraseña?</p>
            
            <div class="row">
              <div class="col-6">
                <button class="btn btn-primary btn-block btn-hover" v-bind:class="{ 'button-pressed': button1Pressed }" style="background-color: #E7E6E6; border: none" @click="EnvioSMS()">
                  <div>
                    <i class="far fa-comment fa-4x"></i>
                  </div>
                  <div style="color: #595959;">
                    Por Mensaje de texto
                  </div>
                </button>
              </div>
              <div class="col-6">
                <button class="btn btn-primary btn-block btn-hover" v-bind:class="{ 'button-pressed': button2Pressed }" style="background-color: #E7E6E6; border: none" @click="EnvioCorreo()">
                  <div>
                    <i class="fa fa-envelope fa-4x"></i>
                  </div>
                  <div style="color: #595959;">
                    Por Correo electronico
                  </div>
                </button>
              </div>
            </div>

            <!-- <div class="form-group" v-if="selecciona">
                    
                    <div class="form-check" v-if="CorreoValidado == 1">
                      <input class="form-check-input" type="radio" name="radio1" value="1">
                      <label class="form-check-label" style="color: #000;">Enviar por correo : {{CorreoDatos}}</label>
                    </div>
                    <div class="form-check" v-else>
                      <input class="form-check-input" type="radio" name="radio1" value="1">
                      <label class="form-check-label" style="opacity: 0.2;">Enviar por correo : {{CorreoDatos}}</label>
                    </div>
                    <div class="form-check" v-if="CelularValidado == 1" >
                      <input class="form-check-input" type="radio" name="radio1" value="2">
                      <label class="form-check-label" style="color: #000;">Enviar por SMS : {{CelularDatos}}</label>
                    </div>
                    <div class="form-check" v-else>
                      <input class="form-check-input" type="radio" name="radio1" value="2">
                      <label class="form-check-label" style="opacity: 0.2;">Enviar por SMS : {{CelularDatos}}</label>
                    </div>
                    <p class="nota">{{RestablecerPassword.sNotaInformativa}}</p>
            </div> -->

            <div class="row">       
    
              <div class="overlay-wrapper"  v-if="load">
                <div class="overlay" style="flex-direction: column;">
                  <i class="fas fa-3x fa-sync-alt fa-spin"></i>
                  <div class="text-bold pt-2">Cargando...</div>
                </div>
              </div>
            </div>

          </form>

          <p class="mt-3 mb-1" style="text-align: center;">
            <a href="https://api.whatsapp.com/send/?phone=5117097221&text&type=phone_number&app_absent=0" style="color:#5A2D82 !important" >
              <img src="../assets/img/wtsp-icon.png" alt="Icono" style="height: 20px; width: 20px; border-radius: 25%;">
              Necesito ayuda
            </a>
          </p>   
        </div>
      </div>
    </div>
  </body>

  <ModalMessage v-show="ModalMessage.isModalMessage" :msj="ModalMessage">
  </ModalMessage>
  
  <TrueMessage v-show="TrueMessage.isTrueMessage" :msj="TrueMessage">
  </TrueMessage>

</template>

<script>

import axios from "axios";
export default {
    data: () => ({
      correo: "",
      CorreoDatos:"",
      CelularDatos:"",
      CorreoValidado:"",
      CelularValidado:"",
      RestablecerPassword:[],
      error: false,
      load : false,
      recuperar:true,
      seleciona:false,
      ModalMessage: {
          isModalMessage: false,
      },
      TrueMessage: {
          isTrueMessage: false,
      }
    }),

watch: {
  'ModalMessage.isModalMessage': function (flag) {
    if (!flag) {
      this.button1Pressed = false;
      this.button2Pressed = false;
    }
  },
},

methods: {
  EnvioSMS(){
    this.button1Pressed = true;

      let ipbackendoms = this.$store.state.backendoms;  //  ------- uncomment for testing //
      //let ipbackendoms = "https://localhost:44342"
      if(this.correo != ""){
        var dataprocess = "";
        var email = this.correo;
        dataprocess = email.trim();
      } 
      else {
        const Toast =  Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000
            }).fire({
              icon: 'error',
              title: 'Debe llenar el campo con el dato correspondiente'
            });
        return   
      }
      axios.get(ipbackendoms+"/api/Acceso/ValidarUsuario?sUsuario="+this.correo)
      .then((res)=>{
          this.RestablecerPassword = res.data.oDatosUsuario;
          console.log(res.data.oDatosUsuario)
        if (res.data.oResult.bSatisfactorio == true) {
          if (res.data.oDatosUsuario.sCelularReal != "") {
            this.CorreoDatos = this.RestablecerPassword.sCorreoReal;
            this.CelularDatos = this.RestablecerPassword.sCelularReal;
            this.CorreoValidado = 1 //this.RestablecerPassword.nCorreoValido;
            this.CelularValidado = 1 //this.RestablecerPassword.nCelularValido;

            var bodyFormData = new FormData();
            bodyFormData.append("sUsuario", dataprocess);
            axios.post(ipbackendoms+"/api/Acceso/EnviarSMSFree", bodyFormData)

            .then((response) => {
            const data_res = response.data.bSatisfactorio;
            const data_sms = response.data.sMessage
            console.log(data_res)
        
            if (data_res == true) { 
              this.correo= "";
              this.TrueMessage.isTrueMessage = true;
              this.TrueMessage.titulo = "Mensaje Smart";
              this.TrueMessage.mensaje = 'La solicitud para restablecer su contraseña ha sido enviado al celular '+ this.CelularDatos;
            }else{
              this.correo= "";
              this.ModalMessage.isModalMessage = true;
              this.ModalMessage.titulo = "Mensaje Smart";
              this.ModalMessage.mensaje = data_sms;
            }
            });
          } else {
            const Toast =  Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000
            }).fire({
              icon: 'error',
              title: 'El usuario no tiene un numero registrado'
            });
          }          
        }
        else {
        const Toast =  Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000
            }).fire({
              icon: 'error',
              title: res.data.oResult.sMessage
            });
          return
        }
      })
      .catch((error) => {
        console.log(error)
      });
  },


   EnvioCorreo(){
      this.button2Pressed = true;

      let ipbackendoms = this.$store.state.backendoms;   // ------- uncomment for testing //
      // let ipbackendoms = "https://localhost:44342"
      if(this.correo != ""){
        var dataprocess = "";
        var email = this.correo;
        dataprocess = email.trim();
      } 
      else {
        const Toast =  Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000
            }).fire({
              icon: 'error',
              title: 'Debe llenar el campo con el dato correspondiente'
            });
        return   
      }

      axios.get(ipbackendoms+"/api/Acceso/ValidarUsuario?sUsuario="+this.correo)
      .then((res)=>{
          this.RestablecerPassword = res.data.oDatosUsuario;
          console.log(res.data.oDatosUsuario)
        if (res.data.oResult.bSatisfactorio == true) {
          if (res.data.oDatosUsuario.sCorreoReal != "") {
            this.CorreoDatos = this.RestablecerPassword.sCorreoReal;
            this.CelularDatos = this.RestablecerPassword.sCelularReal;
            this.CorreoValidado = 1 //this.RestablecerPassword.nCorreoValido;
            this.CelularValidado = 1 //this.RestablecerPassword.nCelularValido;

            var bodyFormData = new FormData();
            bodyFormData.append("sCodUsuario", dataprocess);
            axios.post(ipbackendoms+"/api/Administracion/EnviarCorreoRestablecer", bodyFormData)
            .then((response) => {
            const data_res = response.data.bSatisfactorio;
            const data_sms = response.data.sMessage; 
            console.log(data_res)
        
            if (data_res == true) { 
              this.correo= "";
              this.TrueMessage.isTrueMessage = true;
              this.TrueMessage.titulo = "Mensaje Smart";
              this.TrueMessage.mensaje = 'La solicitud para restablecer su contraseña ha sido enviado al correo <a href="mailto:' + this.CorreoDatos + '" style="color: blue; text-decoration: underline;">' + this.CorreoDatos + '</a>' + '<br><small><i>**De tener inconvenientes dar clic en  <a href="https://api.whatsapp.com/send/?phone=5117097221&text&type=phone_number&app_absent=0" style="color: blue; text-decoration: underline;">Necesito ayuda</a></i></small>';            
            }else{
              this.correo= "";
              this.ModalMessage.isModalMessage = true;
              this.ModalMessage.titulo = "Mensaje Smart";
              this.ModalMessage.mensaje = data_sms;
            }
            });
          } else {
            const Toast =  Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000
            }).fire({
              icon: 'error',
              title: 'El usuario no tiene un correo registrado'
            });
          }          
        }
        else {
        const Toast =  Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000
            }).fire({
              icon: 'error',
              title: res.data.oResult.sMessage
            });
          return
        }
      })
      .catch((error) => {
        console.log(error)
      });
   }
},
}
</script>
<style scoped>
/* CSS Login - Rutas*/
@import './../assets/plugins/fontawesome-free/css/all.min.css';
@import './../assets/css/adminlte.min.css';
@import './../assets/css/sokso_login.css';
@import './../assets/css/adminlte.css';
@import './../assets/plugins/icheck-bootstrap/icheck-bootstrap.min.css';

.btn-hover:hover,
.button-pressed {
  background-color: #7732A6 !important;
}

.btn-hover:hover div,
.button-pressed div {
  color : #fff !important;
}

.swal2-container {
  width: 100%;
  height: auto;
}
.swal2-popup.swal2-toast.swal2-show {
  margin: 0 auto;
}
.nota{
  margin-top: 1rem;
  font-size: 11px;
  text-align: justify;
  color: #000;
  font-weight: 700;
}
</style>